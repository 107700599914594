import React, { useState } from 'react'
import {
  Flex,
  Text,
  useMediaQuery,
  useTheme,
  IconButton,
  Stack,
  Box,
  Container,
} from '@chakra-ui/react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { listImages } from '../resource/list';
import "../styles/styled.css";
import "../styles/descripcion.css";
import ArrowLeftIcon from "../assets/svgs/ArrowLeftSVG";
import ArrowRightIcon from "../assets/svgs/ArrowRightSVG";

const Desarrollador = () => {
  const { breakpoints } = useTheme();
  const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.lg})`);
  const [isGreaterThanLg] = useMediaQuery(`(min-width: ${breakpoints.lg})`);
  const [activeIndex, setActiveIndex] = useState(0);

  const slidePrev = () => {
    activeIndex === 0 ? (
      setActiveIndex(listImages[1].images.length - 1)) :
      setActiveIndex(activeIndex - 1);
  };

  const slideNext = () => {
    activeIndex === listImages[1].images.length - 1 ? (
      setActiveIndex(0))
      : setActiveIndex(activeIndex + 1)
  };

  const assets = [
    { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/thedistrict/logos/logo-sma.svg' },
    { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/thedistrict/icons/SSC.png' }
  ];

  const TextSCC = () => (
    <Flex width={"100%"}>
      <Box mt={10}>
        <img src={assets[1].imgUrl} width='120' height='120' alt='descripcion' />
        <Text
          mt={5}
          fontFamily={'AktivGrotesk-Regular'}
          fontSize={'22px'}
          lineHeight={'21.6px'}>
          Arquitectura y funcionalidad que surgen de la innovación
        </Text>
        <br />
        <Text
          fontSize={"18px"}
          fontWeight={400}
          fontFamily={'AktivGrotesk-Light'}
          textAlign={{ base: "justified", lg: "left" }}
          lineHeight={"1.2"}
        >
          Nuestros proyectos residenciales son diseñados para hacer que la vida cotidiana sea más relajada, completa y cómoda para todas las familias que nos eligen como escenario para comenzar un nuevo hogar.
          <br />
          Con cada uno de nuestros proyectos buscamos crear atmósferas que reúnan los servicios, la comodidad y la seguridad que las personas requieren en su vida diaria.
        </Text>
      </Box>
    </Flex>
  )

  return (
    <Container maxW='7xl' my={'10rem'} px={{ base: 4, md: 10, lg: 10 }} id="desarrollador">
      <Flex width='100%' display={{ base: 'block', lg: 'flex' }}>
        <Box
          width={{ base: '100%', lg: '50%' }}
          mr={{ base: 0, lg: 10 }}
          mb={{ base: 10, lg: 0 }}
          display={{ base: 'none', lg: 'block' }}>
          <TextSCC />
        </Box>

        <Stack
          width={{ base: '90%', sm: '80%', md: '70%', lg: '55%' }}
          ml={{ base: -5, sm: 8, md: 16, lg: -5, xl: 0 }}
          align='center'
        >
          <Flex
            className='section-construvida-carousel'
            width={{ base: "100%", sm: '80%', md: '400px', lg: '450px' }}
            alignItems='center'
          >
            <IconButton
              icon={<ArrowLeftIcon color="black" />}
              rounded="full"
              border="0"
              shadow="md"
              transitionDuration=".3s"
              _hover={{ shadow: "lg" }}
              //disabled={page <= 1}
              onClick={slidePrev}
              position="relative"
              right={{ base: "-4", md: -5 }}
              bg="white"
              zIndex="2"
            />
            <AliceCarousel
              mouseTracking
              //disableDotsControls
              //animationType="fadeout" 
              animationDuration={150}
              disableButtonsControls
              activeIndex={activeIndex}
              items={listImages[3].images}
              responsive={true}
              controlsStrategy="responsive"
              animationType='fadeout'
              //autoPlay={true}
              autoPlayInterval={5000}
              infinite={true}
              keyboardNavigation={true}
              disableDotsControls
              //autoHeight={false}
              maxHeight="500px"
            />
            <IconButton
              icon={<ArrowRightIcon color="black" />}
              rounded="full"
              border="0"
              colorScheme="brand"
              shadow="md"
              transitionDuration=".3s"
              _hover={{ shadow: "lg" }}
              onClick={slideNext}
              position="relative"
              left={{ base: "-4", md: -5 }}
              bg="white"
              zIndex="2"
            />
          </Flex>
        </Stack>
        <Box
          width={{ base: '100%', lg: '50%' }}
          mr={{ base: 0, lg: 10 }}
          mb={{ base: 10, lg: 0 }}
          display={{ base: 'block', lg: 'none' }}>
          <TextSCC />
        </Box>
      </Flex>

      <Flex width='100%' display={{ base: 'block', lg: 'flex' }} mt='5rem'>
        <Stack
          width={{ base: '90%', sm: '80%', md: '70%', lg: '55%' }}
          ml={{ base: -5, sm: 8, md: 16, lg: -5, xl: 0 }}
          align={{ base: 'center', lg: 'flex-start' }}
        >
          <Flex
            className='section-construvida-carousel'
            width={{ base: "100%", sm: '80%', md: '400px', lg: '450px' }}
            alignItems='center'
          >
            <IconButton
              icon={<ArrowLeftIcon color="black" />}
              rounded="full"
              border="0"
              shadow="md"
              transitionDuration=".3s"
              _hover={{ shadow: "lg" }}
              //disabled={page <= 1}
              onClick={slidePrev}
              position="relative"
              right={{ base: "-4", md: -5 }}
              bg="white"
              zIndex="2"
            />
            <AliceCarousel
              mouseTracking
              //disableDotsControls
              //animationType="fadeout" 
              animationDuration={150}
              disableButtonsControls
              activeIndex={activeIndex}
              items={listImages[2].images}
              responsive={true}
              controlsStrategy="responsive"
              animationType='fadeout'
              //autoPlay={true}
              autoPlayInterval={5000}
              infinite={true}
              keyboardNavigation={true}
              disableDotsControls
              //autoHeight={false}
              maxHeight="500px"
            />
            <IconButton
              icon={<ArrowRightIcon color="black" />}
              rounded="full"
              border="0"
              colorScheme="brand"
              shadow="md"
              transitionDuration=".3s"
              _hover={{ shadow: "lg" }}
              onClick={slideNext}
              position="relative"
              left={{ base: "-4", md: -5 }}
              bg="white"
              zIndex="2"
            />
          </Flex>
        </Stack>
        <Box
          width={{ base: '100%', lg: '50%' }}
          ml={{ base: 0, lg: 10 }}
          mb={{ base: 10, lg: 0 }}
        >
        <Flex width={"100%"}>
          <Box mt={10}>
            <img src={assets[0].imgUrl} width='120' height='120' alt='descripcion' />
            <Text
              mt={5}
              fontFamily={'AktivGrotesk-Regular'}
              fontSize={'22px'}
              lineHeight={'21.6px'}>
              Soluciones arquitectónicas conscientes e integradas
            </Text>
            <br />
            <Text
              fontSize={"18px"}
              fontWeight={400}
              fontFamily={'AktivGrotesk-Light'}
              textAlign={{ base: "justified", lg: "left" }}
              lineHeight={"1.2"}
            >
              Un estudio con una trayectoria mayor a 80 años que durante tres generaciones se ha especializado en la creación de experiencias arquitectónicas únicas e innovadoras.
              <br />
              La filosofía de Sordo Madaleno está basada en conceptos que tienen el poder de dar, transformar y elevar las ciudades, para así mejorar la vida de sus habitantes a través de vibrantes ofertas culturales.
            </Text>
          </Box>
        </Flex>
      </Box>
    </Flex>
    </Container >
  )
}

export default Desarrollador