import React from 'react';
import { 
    Flex, 
    Text,
    Grid,
    GridItem,
    Image
} from '@chakra-ui/react';

const AmenidadesMb = () => {
    const assets = [
        { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/thedistrict/icons/icon1.svg' },
        { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/thedistrict/icons/icon2.svg' },
        { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/thedistrict/icons/icon3.svg' },
        { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/thedistrict/icons/icon4.svg' },
        { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/thedistrict/icons/icon5.svg' },
        { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/thedistrict/icons/icon6.svg' }
    ];

    return ( 
        <Grid id='iconos' templateColumns={"repeat(2, 1fr)"}  px={2} w={'100%'} margin='auto' pb={8} color='#5B5B5F'>
            <GridItem colSpan={2} justifyItems=''>
                <Grid templateColumns={"repeat(2, 1fr)"} px={4} w={'100%'} margin='auto' pb={16} gap={8} justifyItems={'center'}>
                    <GridItem colSpan={1}>
                        <Flex alignItems={"center"} display={'block'} textAlign='-webkit-center'>
                            <Image src={assets[0].imgUrl} boxSize={'60px'}  alt='torre'/>
                            <Text marginLeft={"0.5rem"} fontSize='sm' fontFamily={'AktivGrotesk-Regular'} fontWeight={400} color='#545454' textAlign={'start'}>
                                Primera etapa
                            </Text>
                        </Flex>
                    </GridItem>
                    <GridItem colSpan={1}>
                        <Flex alignItems={"center"} display={'block'} textAlign='-webkit-center'>
                            <Image src={assets[1].imgUrl} boxSize={'60px'} alt='departamentos'/>
                            <Text marginLeft={"0.5rem"} fontSize='sm' fontFamily={'AktivGrotesk-Regular'} fontWeight={400} color='#545454' textAlign={'center'}>
                                2 y 3 recámaras
                            </Text>
                        </Flex>
                    </GridItem>
                    <GridItem colSpan={1}>
                        <Flex alignItems={"center"} display={'block'} textAlign='-webkit-center'>
                            <Image src={assets[2].imgUrl} boxSize={'60px'} alt='recamaras'/>
                            <Text marginLeft={"0.5rem"} fontSize='sm' fontFamily={'AktivGrotesk-Regular'} fontWeight={400} color='#545454' textAlign={'center'}>
                                Desde 89.82 m<sup>2</sup> <br/> hasta 408 m<sup>2</sup>
                            </Text>
                        </Flex>
                    </GridItem>
                    <GridItem colSpan={1} display={'block'}>
                        <Flex  alignItems={"center"} display={'block'} textAlign='-webkit-center'>
                            <Image src={assets[3].imgUrl} boxSize={'60px'} alt='metros'/>
                            <Text marginLeft={"0.5rem"} fontSize='sm' fontFamily={'AktivGrotesk-Regular'} fontWeight={400} color='#545454' textAlign={'center'}>
                                Área comercial
                            </Text>
                        </Flex>
                    </GridItem>
                </Grid>
            </GridItem>
            <GridItem  colSpan={2} >
                <Grid id='justify'  templateColumns={"repeat(2, 1fr)"}  px={4} w={'100%'} margin='auto' pb={16} gap={8} justifyItems={'center'} >
                    <GridItem colSpan={1}>
                        <Flex alignItems={"center"} display={'block'} textAlign='-webkit-center'>
                            <Image src={assets[4].imgUrl} boxSize={'60px'} alt='amenidades'/>
                            <Text marginLeft={"0.5rem"} fontSize='sm' fontFamily={'AktivGrotesk-Regular'} fontWeight={400} color='#545454' textAlign={'center'}>
                                Vistas increíbles al<br />campo de golf
                            </Text>
                        </Flex>
                    </GridItem>
                    <GridItem colSpan={1}>
                        <Flex alignItems={"center"} display={'block'} textAlign='-webkit-center'>
                            <Image src={assets[5].imgUrl} boxSize={'60px'} alt='financiamiento'/>
                            <Text marginLeft={"0.5rem"} fontSize='sm' fontFamily={'AktivGrotesk-Regular'} fontWeight={400} color='#545454' textAlign={'center'}>
                                Estacionamiento<br/>techado
                            </Text>
                        </Flex>
                    </GridItem>
                </Grid>
            </GridItem>
        </Grid>
    );
}
 
export default AmenidadesMb;