import React, { useState, useEffect } from 'react'
import {
    Flex,
    useMediaQuery,
    useTheme,
    Box,
    Text,
    Button,
    Link,
} from '@chakra-ui/react';
import ButtonActionModal from './ButtonActionModal';
import '../styles/parallax.css';

const CTA = ({ ctaNum, text1, text2, text3, font1, font2, font3, fontMb1, fontMb2, fontMb3, type1, type2, type3, ...props }) => {
    const { breakpoints } = useTheme();
    const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.md})`);
    const [screenSize, getDimension] = useState({
        winWidth: window.innerWidth,
        winHeight: window.innerHeight,
    });

    const assets = [
        { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/thedistrict/fondo/fondobg-1.webp', imgUrlMb: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/thedistrict/fondo/mobile/fondobg-Mb-1.png' },
        { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/thedistrict/fondo/fondobg-2.webp', imgUrlMb: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/thedistrict/fondo/mobile/fondobg-Mb-2.png' },
        { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/thedistrict/fondo/fondobg-3.webp', imgUrlMb: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/thedistrict/fondo/mobile/fondobg-Mb-3.png' }
    ];

    const setDimensions = () => {
        getDimension({
            winWidth: window.innerWidth,
            winHeight: window.innerHeight,
        });
    };

    useEffect(() => {
        window.addEventListener("resize", setDimensions);

        return () => {
            window.removeEventListener("resize", setDimensions);
        };
    }, [screenSize]);

    return (
        <Box id='cta' {...props} w='full' paddingInline="0" position="relative" >
            <Flex id='inicio'
                h={"70vh"}
                className='fondo-district'
                backgroundImage={{ base: assets[ctaNum].imgUrlMb, lg: assets[ctaNum].imgUrl }}
                backgroundSize={'cover'}
                backgroundRepeat={'no-repeat'}
                backgroundPosition={'center'}
            >
                <Flex
                    height="70vh"
                    w="100%"
                    position={"absolute"}
                    backgroundColor={"#0f312c71"}>
                </Flex>
                <Flex zIndex={1} flexDirection={"column"} color={"#FFFFFF"} textAlign={"center"}
                    alignItems={"center"} width={"100%"}>
                    <Text lineHeight={1.5} fontSize={isGreaterThanMd ? font1 : fontMb1} color={'white'} fontFamily={'AktivGrotesk-Medium'} letterSpacing='wider' textTransform={'uppercase'}>
                        {text1}
                    </Text>
                    <Text lineHeight={1} fontSize={isGreaterThanMd ? font2 : fontMb2} color={'white'} fontFamily={'AktivGrotesk-Medium'} letterSpacing='wider' textTransform={ctaNum === 1 ? 'inherit' : 'uppercase'}>
                        {text2}
                    </Text>
                    {ctaNum === 1 ?
                        <Text fontSize={isGreaterThanMd ? '26px' : '20px'} color={'white'} fontFamily={'AktivGrotesk-Medium'} letterSpacing='wider' textTransform={'inherit'}>
                            desde 87 m<sup>2</sup> hasta 408 m<sup>2</sup>
                        </Text> : null
                    }
                    <br />
                    {/* <Link target='_blank' href='https://share.hsforms.com/1M4tSYjPkRKi26GFHl9MRKQ3j49k'>
                        <Button
                            bg='#E76751'
                            px={2}
                            _hover={{
                                bg: '#B75241'
                            }}
                        >
                            QUIERO SER CONTACTADO
                        </Button>
                    </Link> */}
                    <ButtonActionModal
                        title={'QUIERO SER CONTACTADO'}
                        title_form={'form_quiero_saber_mas'}
                        idFormHs={"338b5262-33e4-44a8-b6e8-614797d31129"}
                        thankYouPage={"thankyoupage-agenda-cita"}
                        bgColor={"#E76751"}
                        hover={"#B75241"}
                        fontColor='#FFF'
                        txtSize={700}
                        p={'11px'}
                        px={3}
                        size='30px'
                        fontSize={isGreaterThanMd ? 'lg' : 'sm'}
                        w={isGreaterThanMd ? 'auto' : 'full'}
                        alignSelf={'center'}
                    />
                </Flex>
            </Flex>
        </Box>
    )
}

export default CTA