import { Flex, Image, Text, Grid, GridItem, Box } from '@chakra-ui/react';
import '../styles/styled.css';
import CircleIcon from '../assets/svgs/CircleSVG';

const assets1 = [
    { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/thedistrict/amenidades/bar.webp' },
    { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/thedistrict/amenidades/gym.webp' },
    { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/thedistrict/amenidades/terraza.webp' }
];

const assets2 = [
    { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/thedistrict/prototipos/img1.png' },
    { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/thedistrict/prototipos/img2.png' },
    { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/thedistrict/prototipos/img3.png' },
    { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/thedistrict/prototipos/img4.png' },
    { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/thedistrict/prototipos/img5.png' },
    { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/thedistrict/prototipos/model_l.webp' },
];

const assets3 = [
    { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/thedistrict/desarrollos/img1.svg' },
    { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/thedistrict/desarrollos/img2.svg' },
    { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/thedistrict/desarrollos/img3.svg' }
];
const assets4 = [
    { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/thedistrict/desarrollos/ssc-1.webp' },
    { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/thedistrict/desarrollos/ssc-2.webp' }
];

export const listImages = [
    {
        id: 1,
        images: [
            <Flex className='container-cols-mon'>
                <Flex className='col1-mon' borderRadius={"24px"} backgroundImage={`linear-gradient(#0f312c71, #0f312c71), url('${assets1[1].imgUrl}')`} width={"20%"} margin={"3rem 0px"} backgroundSize={"cover"}></Flex>
                <Flex position={"relative"} className='col2-mon' width={"100%"} padding={"3rem"}>
                    <img
                        style={{ width: "100%" }}
                        src={assets1[0].imgUrl}
                        width='100'
                        height='100'
                        alt='icon' />
                    <Text
                        display={'flex'}
                        justifyContent={'center'}
                        position={'absolute'}
                        color='white'
                        fontFamily={'AktivGrotesk-Medium'}
                        float='right'
                        fontSize={'19px'}
                        fontWeight={'700'}
                        lineHeight={'22.8px'}
                        p={4}
                        pl={16}>
                        BAR
                    </Text>
                </Flex>
                <Flex className='col3-mon' borderRadius={"24px"} backgroundImage={`linear-gradient(#0f312c71, #0f312c71), url('${assets1[1].imgUrl}')`} width={"20%"} margin={"3rem 0px"} backgroundSize={"cover"}></Flex>
            </Flex>,
            <Flex className='container-cols-mon'>
                <Flex className='col1-mon' borderRadius={"24px"} backgroundImage={`linear-gradient(#0f312c71, #0f312c71), url('${assets1[0].imgUrl}')`} width={"20%"} margin={"3rem 0px"} backgroundSize={"cover"}></Flex>
                <Flex className='col2-mon' width={"100%"} padding={"3rem"}>
                    <img
                        style={{ width: "100%" }}
                        src={assets1[1].imgUrl}
                        width='100'
                        height='100'
                        alt='icon' />
                    <Text
                        display={'flex'}
                        justifyContent={'center'}
                        position={'absolute'}
                        color='white'
                        float='left'
                        fontFamily={'AktivGrotesk-Medium'}
                        fontSize={'19px'}
                        fontWeight={'700'}
                        lineHeight={'22.8px'}
                        p={4}
                        pl={16}>
                        GYM
                    </Text>
                </Flex>
                <Flex className='col3-mon' borderRadius={"24px"} backgroundImage={`linear-gradient(#0f312c71, #0f312c71), url('${assets1[2].imgUrl}')`} width={"20%"} margin={"3rem 0px"} backgroundSize={"cover"}></Flex>
            </Flex>,
            <Flex className='container-cols-mon'>
                <Flex className='col1-mon' borderRadius={"24px"} backgroundImage={`linear-gradient(#0f312c71, #0f312c71), url('${assets1[1].imgUrl}')`} width={"20%"} margin={"3rem 0px"} backgroundSize={"cover"}></Flex>
                <Flex className='col2-mon' width={"100%"} padding={"3rem"}>
                    <img
                        style={{ width: "100%" }}
                        src={assets1[2].imgUrl}
                        width='100'
                        height='100'
                        alt='icon' />
                    <Text
                        display={'flex'}
                        justifyContent={'center'}
                        position={'absolute'}
                        color='white'
                        float='left'
                        fontFamily={'AktivGrotesk-Medium'}
                        fontSize={'19px'}
                        fontWeight={'700'}
                        lineHeight={'22.8px'}
                        p={4}
                        pl={16}>
                        TERRAZA
                    </Text>
                </Flex>
                <Flex className='col3-mon' borderRadius={"24px"} backgroundImage={`linear-gradient(#0f312c71, #0f312c71), url('${assets1[0].imgUrl}')`} width={"20%"} margin={"3rem 0px"} backgroundSize={"cover"}></Flex>
            </Flex>,
        ]
    },
    {
        id: 2,
        images: [
            <Flex width={"100%"} padding={"0rem"} my={10} justifyContent={"center"}>
                <Flex justifyContent={"center"} w='100%' color={"#545454"}>
                    <GridItem colSpan={1}>
                        <Image
                            src={assets2[1].imgUrl}
                            width='90%'
                            height='auto'
                            alt='icon'
                        />
                    </GridItem>
                    <GridItem colSpan={1} alignItems={'center'} alignSelf={'center'}>
                        <Text fontFamily={'AktivGrotesk-Medium'} fontSize={'35px'} mb={2}>
                            Modelo B
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'24px'} mb={2}>
                            Descripción:
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={0}>
                            <CircleIcon boxSize={2} /> Desde 117.46 m<sup>2</sup> hasta 159.27 m<sup>2</sup>
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> 2 recámaras con walk-in closet
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> 2.5 baños
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> Sala-comedor
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> Cocina
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> Alacena
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> Cuarto de lavado
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> Terraza
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> 2 cajones de estacionamiento
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> 1 bodega
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> A/A
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> Vista al norte
                        </Text>
                    </GridItem>
                </Flex>
            </Flex>,
            <Flex width={"100%"} padding={"0rem"} my={10} justifyContent={"center"}>
                <Flex justifyContent={"center"} w='100%' color={"#545454"}>
                    <GridItem colSpan={1}>
                        <Image
                            src={assets2[2].imgUrl}
                            width='90%'
                            height='auto'
                            alt='icon'
                        />
                    </GridItem>
                    <GridItem colSpan={1} alignItems={'center'} alignSelf={'center'}>
                        <Text fontFamily={'AktivGrotesk-Medium'} fontSize={'35px'} mb={2}>
                            Modelo C
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'24px'} mb={2}>
                            Descripción:
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={0}>
                            <CircleIcon boxSize={2} /> Desde 168.75 m<sup>2</sup>
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> 3 recámaras
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> 2.5 baños
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> Sala-comedor
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> Cocina
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> Alacena
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> Cuarto de lavado
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> Clóset de blancos
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> Terraza
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> 2 cajones de estacionamiento
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> 1 bodega
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> A/A
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> Vista al nororiente
                        </Text>
                    </GridItem>
                </Flex>
            </Flex>,
            <Flex width={"100%"} padding={"0rem"} my={10} justifyContent={"center"}>
                <Flex justifyContent={"center"} w='100%' color={"#545454"}>
                    <GridItem colSpan={1}>
                        <Image
                            src={assets2[4].imgUrl}
                            width='90%'
                            height='auto'
                            alt='icon'
                        />
                    </GridItem>
                    <GridItem colSpan={1} alignItems={'center'} alignSelf={'center'}>
                        <Text fontFamily={'AktivGrotesk-Medium'} fontSize={'35px'} mb={2}>
                            Modelo E
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'24px'} mb={2}>
                            Descripción:
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={0}>
                            <CircleIcon boxSize={2} /> Desde 82.12 m<sup>2</sup> hasta 108.21 m<sup>2</sup>
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> 2 recámaras
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> 2 baños
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> Sala-comedor
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> Cocina
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> Área de lavado
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> Terraza
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> 2 cajones de estacionamiento
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> 1 bodega
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> A/A
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> Vista al surponiente
                        </Text>
                    </GridItem>
                </Flex>
            </Flex>,
            <Flex width={"100%"} padding={"0rem"} my={10} justifyContent={"center"}>
                <Flex justifyContent={"center"} w='100%' color={"#545454"}>
                    <GridItem colSpan={1}>
                        <Image
                            src={assets2[5].imgUrl}
                            width='90%'
                            height='auto'
                            alt='icon'
                        />
                    </GridItem>
                    <GridItem colSpan={1} alignItems={'center'} alignSelf={'center'}>
                        <Text fontFamily={'AktivGrotesk-Medium'} fontSize={'35px'} mb={2}>
                            Modelo L
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'24px'} mb={2}>
                            Descripción:
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={0}>
                            <CircleIcon boxSize={2} /> 107.79 m<sup>2</sup>
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> 1 recámara
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> 1.5 baños
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} />Cocina
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> Comedor
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> Sala
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> Área de lavado
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> Closet de blancos
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> 1 cajón
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> 1 bodega
                        </Text>
                    </GridItem>
                </Flex>
            </Flex>,
        ],
        imagesMobile: [
            <Flex width={"100%"} padding={"0rem"} justifyContent={'right'} my={10}>
                <Grid templateColumns={"repeat(1, 1fr)"} w='90%'>
                    <GridItem colSpan={1} alignItems={'center'} alignSelf={'center'}>
                        <Image
                            src={assets2[1].imgUrl}
                            width='70%'
                            height='auto'
                            alt='icon'
                            mx='auto'
                        />
                    </GridItem>
                    <GridItem colSpan={1} alignItems={'center'} alignSelf={'center'} px={6} pt={4} color={"#545454"}>
                        <Text textAlign={'center'} fontFamily={'AktivGrotesk-Medium'} fontSize={'30px'} mb={2}>
                            Modelo B
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'22px'} mb={2}>
                            Descripción:
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={0}>
                            <CircleIcon boxSize={2} /> Desde 117.46 m<sup>2</sup> hasta 159.27 m<sup>2</sup>
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> 2 recámaras con walk-in closet
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> 2.5 baños
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> Sala-comedor
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> Cocina
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> Alacena
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> Cuarto de lavado
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> Terraza
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> 2 cajones de estacionamiento
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> 1 bodega
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> A/A
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> Vista al norte
                        </Text>
                    </GridItem>
                </Grid>
            </Flex>,
            <Flex width={"100%"} padding={"0rem"} justifyContent={'right'} my={10}>
                <Grid templateColumns={"repeat(1, 1fr)"} w='90%'>
                    <GridItem colSpan={1} alignItems={'center'} alignSelf={'center'}>
                        <Image
                            src={assets2[2].imgUrl}
                            width='70%'
                            height='auto'
                            alt='icon'
                            mx='auto'
                        />
                    </GridItem>
                    <GridItem colSpan={1} alignItems={'center'} alignSelf={'center'} px={6} pt={4} color={"#545454"}>
                        <Text textAlign={'center'} fontFamily={'AktivGrotesk-Medium'} fontSize={'35px'} mb={2}>
                            Modelo C
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'24px'} mb={2}>
                            Descripción:
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={0}>
                            <CircleIcon boxSize={2} /> Desde 168.75 m<sup>2</sup>
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> 3 recámaras
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> 2.5 baños
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> Sala-comedor
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> Cocina
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> Alacena
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> Cuarto de lavado
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> Clóset de blancos
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> Terraza
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> 2 cajones de estacionamiento
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> 1 bodega
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> A/A
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> Vista al nororiente
                        </Text>
                    </GridItem>
                </Grid>
            </Flex>,
            <Flex width={"100%"} padding={"0rem"} justifyContent={'right'} my={10}>
                <Grid templateColumns={"repeat(1, 1fr)"} w='90%'>
                    <GridItem colSpan={1} alignItems={'center'} alignSelf={'center'}>
                        <Image
                            src={assets2[4].imgUrl}
                            width='70%'
                            height='auto'
                            alt='icon'
                            mx='auto'
                        />
                    </GridItem>
                    <GridItem colSpan={1} alignItems={'center'} alignSelf={'center'} px={6} pt={4} color={"#545454"}>
                        <Text textAlign={'center'} fontFamily={'AktivGrotesk-Medium'} fontSize={'35px'} mb={2}>
                            Modelo E
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'24px'} mb={2}>
                            Descripción:
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={0}>
                            <CircleIcon boxSize={2} /> Desde 82.12 m<sup>2</sup> hasta 108.21 m<sup>2</sup>
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> 2 recámaras
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> 2 baños
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> Sala-comedor
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> Cocina
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> Área de lavado
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> Terraza
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> 2 cajones de estacionamiento
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> 1 bodega
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> A/A
                        </Text>
                        <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                            <CircleIcon boxSize={2} /> Vista al surponiente
                        </Text>
                    </GridItem>
                </Grid>
            </Flex>,
            <Flex width={"100%"} padding={"0rem"} justifyContent={'right'} my={10}>
            <Grid templateColumns={"repeat(1, 1fr)"} w='90%'>
                <GridItem colSpan={1} alignItems={'center'} alignSelf={'center'}>
                    <Image
                        src={assets2[5].imgUrl}
                        width='70%'
                        height='auto'
                        alt='icon'
                        mx='auto'
                    />
                </GridItem>
                <GridItem colSpan={1} alignItems={'center'} alignSelf={'center'} px={6} pt={4} color={"#545454"}>
                    <Text textAlign={'center'} fontFamily={'AktivGrotesk-Medium'} fontSize={'35px'} mb={2}>
                        Modelo L
                    </Text>
                    <Text fontFamily={'AktivGrotesk-Light'} fontSize={'24px'} mb={2}>
                        Descripción:
                    </Text>
                    <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={0}>
                        <CircleIcon boxSize={2} /> 107.79 m<sup>2</sup>
                    </Text>
                    <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                        <CircleIcon boxSize={2} /> 1 recámara
                    </Text>
                    <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                        <CircleIcon boxSize={2} /> 1.5 baños
                    </Text>
                    <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                        <CircleIcon boxSize={2} /> Cocina
                    </Text>
                    <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                        <CircleIcon boxSize={2} /> Comedor
                    </Text>
                    <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                        <CircleIcon boxSize={2} /> Sala
                    </Text>
                    <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                        <CircleIcon boxSize={2} /> Área de lavado
                    </Text>
                    <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                        <CircleIcon boxSize={2} /> Closet de blancos
                    </Text>
                    <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                        <CircleIcon boxSize={2} /> 1 cajón
                    </Text>
                    <Text fontFamily={'AktivGrotesk-Light'} fontSize={'20px'} mb={1}>
                        <CircleIcon boxSize={2} /> 1 bodega
                    </Text>
                </GridItem>
            </Grid>
        </Flex>,
        ]
    },
    {
        id: 3,
        images: [
            // <div style={{ backgroundImage: `url('${assets3[0].imgUrl}')`, backgroundSize: 'cover', backgroundPosition: 'center' }} className='fondo-soare'>
            //     <Flex paddingLeft={"1rem"} paddingRight={"1rem"} textAlign={"center"} justifyContent={"center"} paddingBottom={"2rem"} zIndex={2}>
            //         <Text color={"#FFFFFF"} fontWeight={"bold"} fontSize='lg'>DISTRITO LA PERSEVERANCIA</Text>
            //     </Flex>
            // </div>,

            <Flex placeItems={'start'} w='100%' h={'100%'} mx='auto'>
                <Image src={assets3[0].imgUrl} objectFit='cover' w='auto' h={'100%'} alt='icon' />
                {/* <Box bgImage={assets3[0].imgUrl} h='100%' w='auto'> */}
                    {/* <Text
                        zIndex={3}
                        // bottom='2rem'
                        // left='6rem'
                        // align='center'
                        // zIndex={2}
                        // position={'absolute'}
                        // ml={{ base: '10%', sm: '15%', md: '23%' }}
                        fontSize={"14px"}
                        color={'#FFF'}
                        fontFamily={"AktivGrotesk-Bold"}
                        textTransform={'uppercase'}
                    >
                        DISTRITO LA PERSEVERANCIA
                    </Text> */}
                {/* </Box> */}
            </Flex>,
            // <Flex placeItems={'start'} w='100%' h={'100%'} mx='auto'>
            //     <Image src={assets3[1].imgUrl} objectFit='cover' w='auto' h={'100%'} alt='icon'/>
            //     <Text
            //         height={'72px'}
            //         display={'flex'}
            //         position={'absolute'}
            //         py={6}
            //         px={{base: 0, md: 4}}
            //         ml={4}
            //         fontSize={"14px"}
            //         color={'#FFF'}
            //         fontFamily={"AktivGrotesk-Bold"}
            //         textTransform={'uppercase'}
            //     >
            //         LAKE AUSTIN
            //     </Text>
            // </Flex>,
            <Flex placeItems={'start'} w='100%' h={'100%'} mx='auto'>
                <Image src={assets3[2].imgUrl} objectFit='cover' w='auto' h={'100%'} alt='icon' />
                {/* <Text
                    height={'72px'}
                    display={'flex'}
                    position={'absolute'}
                    py={6}
                    px={{ base: 0, md: 4 }}
                    ml={4}
                    fontSize={"14px"}
                    color={'#FFF'}
                    fontFamily={"AktivGrotesk-Bold"}
                    textTransform={'uppercase'}
                >
                    ARBIA TOWER
                </Text> */}
            </Flex>
        ]
    },
    {
        id: 4,
        images: [
            <Flex placeItems={'start'} w='100%' h={'100%'} mx='auto'>
                <Image src={assets4[0].imgUrl} objectFit='cover' w='auto' h={'100%'} alt='icon' />
            </Flex>,
            <Flex placeItems={'start'} w='100%' h={'100%'} mx='auto'>
                <Image src={assets4[1].imgUrl} objectFit='cover' w='auto' h={'100%'} alt='icon' />
            </Flex>
        ]
    }
];
