import React from 'react';
import { 
    Flex,
    Tab,
    Tabs,
    TabPanels,
    TabPanel,
    TabList,
    Grid,
    GridItem,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    TableContainer, 
    TableCaption,
    Text,
    useMediaQuery,
    useTheme,
    
} from '@chakra-ui/react';
import { list } from '../resource';
import TableMobile from './TableMobile';

const Planes = () => {
    const { breakpoints } = useTheme();
    const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.md})`);

    return ( 
        <Flex
            color={"#545454"}
            width={"100%"}
            id='planes-pago'
        >
            <Grid
                templateColumns={"repeat(1, 1fr)"}
                w={"100%"}
                pl={"12%"}
                pt={isGreaterThanMd ? 10 : 0}
                >
                <GridItem w="90%">
                <Flex flexDirection={"column"} mb={"1rem"} mt={0}>
                    <Flex justifyContent={"center"}>
                        <Text textTransform={"uppercase"} fontFamily={'AktivGrotesk-Medium'} lineHeight={'89.2px'} fontWeight={700} fontSize={isGreaterThanMd?"50px":"25px"} color={"#0F312C"}>
                            Planes de pago
                        </Text>
                    </Flex>
                    </Flex>
                    {isGreaterThanMd ?
                        <Tabs variant='enclosed'>
                            <TabList width={"100%"}  justifyContent={'center'} fontFamily={'AktivGrotesk-Regular'}>
                                <Tab id='tab1' fontSize={'22px'} _selected={{ bg: "#E76751", color: "#fff", fontWeight: "700", fontSize:'22px', fontFamily: 'AktivGrotesk-Medium' }}>Contado</Tab>
                                <Tab id='tab2' fontSize={'22px'} _selected={{ bg: "#E76751", color: "#fff", fontWeight: "700", fontSize:'22px', fontFamily: 'AktivGrotesk-Medium' }}>Contado comercial</Tab>
                                <Tab id='tab3' fontSize={'22px'} _selected={{ bg: "#E76751", color: "#fff", fontWeight: "700", fontSize:'22px', fontFamily: 'AktivGrotesk-Medium' }}>The District</Tab>
                                <Tab id='tab4' fontSize={'22px'} _selected={{ bg: "#E76751", color: "#fff", fontWeight: "700", fontSize:'22px', fontFamily: 'AktivGrotesk-Medium' }}>Investor</Tab>
                                <Tab id='tab5' fontSize={'22px'} _selected={{ bg: "#E76751", color: "#fff", fontWeight: "700", fontSize:'22px', fontFamily: 'AktivGrotesk-Medium' }}>New owner</Tab>
                            </TabList>
                            <TabPanels>
                                {list.map((item, idx) => (
                                    <TabPanel key={idx}>
                                        <TableContainer>
                                            <Table size='lg' >
                                                <TableCaption fontFamily={'AktivGrotesk-Medium'} fontWeight={300} fontSize={"14px"}>
                                                    La presente política comercial, la cual detalla las diferentes formas de pago, está sujeta a cambios en cualquier momento y sin previo aviso en función de la demanda. <br /> 
                                                    Válida solo para el momento de la consulta. Este documento no es, ni sustituye una cotización, el precio total de la operación se determinará en función de los montos variables por conceptos de descuentos,<br />  
                                                    intereses, créditos directos o hipotecarios participantes, así como gastos notariales según apliquen, los cuales pueden ser consultados y cotizados por su asesor inmobiliario de Grupo Guía.<br /> 
                                                    Para más información consulta nuestra página web www.grupoguia.mx.  *Precios en M.N (Moneda Nacional).
                                                </TableCaption>
                                                <Thead>
                                                    <Tr>
                                                        <Th color={"#E76751"} fontSize={'24px'}  textTransform={"inherit"} fontFamily={'AktivGrotesk-Medium'} fontWeight={700}>Descuento</Th>
                                                        <Th color={"#E76751"} fontSize={'24px'} fontFamily={'AktivGrotesk-Medium'} fontWeight={700}>{item.num}</Th>
                                                    </Tr>
                                                </Thead>
                                                <Tbody>
                                                    <Tr>
                                                        {/* <Th borderColor={'white'} fontSize={'20px'} fontFamily={'AktivGrotesk-Medium'} fontWeight={600}>{item.pay}</Th> */}
                                                        
                                                    </Tr>
                                                    {item.tab.map((element, idx) => (
                                                        <Tr>
                                                            <Th borderColor={'white'}  textTransform={"inherit"} fontSize={'24px'} fontFamily={'AktivGrotesk-Regular'} fontWeight={400}>{element.item1}</Th>
                                                            <Th borderColor={'white'}  textTransform={"inherit"} fontSize={'24px'} fontFamily={'AktivGrotesk-Regular'} fontWeight={400}>{element.item2}</Th>
                                                        </Tr>
                                                    ))}
                                                </Tbody>
                                            </Table>
                                        </TableContainer>
                                    </TabPanel>
                                ))}
                            </TabPanels>
                        </Tabs>: <TableMobile />
                    }
                </GridItem>
            </Grid>
        </Flex>
    );
}
 
export default Planes;
