import React from 'react';
import {
    Button,
    useDisclosure,
    Image
} from '@chakra-ui/react';
import ModalContacto from './ModalContacto';

const ButtonActionModal = ({ title, title_form, size = "auto", idFormHs, thankYouPage, bgColor, hover, txtSize, fontColor, isImage=false, srcImg, ...props }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            {isImage ?
                 <Image 
                    src={srcImg} 
                    w={{ base: 10, md: "50px" }} 
                    cursor={'pointer'}
                    onClick={onOpen}
                    _hover={{transform:'scale(1.1)'}}
                />
            :
                <Button
                    fontSize={'sm'}
                    fontWeight={txtSize}
                    variant={'link'}
                    bg={bgColor}
                    color={fontColor?fontColor:'white'}
                    padding={3}
                    paddingBlock={'18px'}
                    paddingLeft={6}
                    paddingRight={6}
                    as='a'
                    height={size}
                    onClick={onOpen}
                    borderRadius='1px'
                    //display={{ base: 'none', md: 'flex' }}
                    _hover={{
                        bg: hover,
                        cursor: "pointer"
                    }}
                    {...props}
                >
                    {title}
                </Button>
            }
            {isOpen ?
                <ModalContacto 
                    isOpen={isOpen} 
                    onClose={onClose}
                    title={title}
                    title_form={title_form}
                    idForm={idFormHs}
                    thankyoupage={thankYouPage} 
                /> 
                :
                null
            }
        </>
    );
}
 
export default ButtonActionModal;