import React, { useState } from 'react'
import {
    Tab,
    Tabs,
    TabPanels,
    TabPanel,
    TabList,
    Divider,
    Flex,
    Text
} from '@chakra-ui/react';
import { list } from '../resource';

const TableMobile = () => {
    const [activeTab, setActiveTab] = useState(1);

    return (
        <Tabs variant='enclosed' flexDirection={"column"} alignItems={"center"}>
            <TabList width={"max-content"} flexDirection={"column"} margin={"auto"}>
                <Tab fontFamily={"AktivGrotesk-Regular"} borderColor={"#FFF"} pl={10} pr={10} paddingBottom={0}
                    _selected={{ borderColor: "#FFF", borderBottomColor: "#DADADA" }} onClick={() => { setActiveTab(1)}}>
                        {activeTab === 1 ?
                            <Text fontFamily={"AktivGrotesk-Medium"} p={"10px 22px"} fontSize={"20px"} bgColor={"#E76751"} color={"#FFF"} borderRadius={"9px"}>Contado</Text>
                        :   
                            <Text fontFamily={"AktivGrotesk-Regular"} fontSize={"18px"}>Contado</Text>
                        }
                </Tab>
                <Tab mt={2} borderBottomColor={"#FFF"} pl={10} pr={10} paddingBottom={0}
                    _selected={{ borderColor: "#FFF", borderBottomColor: "#DADADA" }} onClick={() => { setActiveTab(2)}}>
                        {activeTab === 2 ?
                            <Text fontFamily={"AktivGrotesk-Medium"} p={"10px 22px"} fontSize={"20px"} bgColor={"#E76751"} color={"#FFF"} borderRadius={"9px"}>Contado comercial</Text>
                        :   
                            <Text fontFamily={"AktivGrotesk-Regular"} fontSize={"18px"}>Contado comercial</Text>
                        }
                </Tab>
                <Tab mt={2} borderColor={"#FFF"} pl={10} pr={10} paddingBottom={0}
                    _selected={{ borderColor: "#FFF", borderBottomColor: "#DADADA" }} onClick={() => { setActiveTab(3)}}>
                        {activeTab === 3 ?
                            <Text fontFamily={"AktivGrotesk-Medium"} p={"10px 22px"} fontSize={"20px"} bgColor={"#E76751"} color={"#FFF"} borderRadius={"9px"}>The District</Text>
                        :   
                            <Text fontFamily={"AktivGrotesk-Regular"} fontSize={"18px"}>The District</Text>
                        }
                </Tab>
                <Tab mt={2} borderColor={"#FFF"} pl={10} pr={10} paddingBottom={0}
                    _selected={{ borderColor: "#FFF", borderBottomColor: "#DADADA" }} onClick={() => { setActiveTab(4)}}>
                        {activeTab === 4 ?
                            <Text fontFamily={"AktivGrotesk-Medium"} p={"10px 22px"} fontSize={"20px"} bgColor={"#E76751"} color={"#FFF"} borderRadius={"9px"}>Inversor</Text>
                        :   
                            <Text fontFamily={"AktivGrotesk-Regular"} fontSize={"18px"}>Inversor</Text>
                        }
                </Tab>
                <Tab mt={2} borderColor={"#FFF"} pl={10} pr={10} paddingBottom={0}
                    _selected={{ borderColor: "#FFF", borderBottomColor: "#DADADA" }} onClick={() => { setActiveTab(5)}}>
                        {activeTab === 5 ?
                            <Text fontFamily={"AktivGrotesk-Medium"} p={"10px 22px"} fontSize={"20px"} bgColor={"#E76751"} color={"#FFF"} borderRadius={"9px"}>New owner</Text>
                        :   
                            <Text fontFamily={"AktivGrotesk-Regular"} fontSize={"18px"}>New owner</Text>
                        }
                </Tab>
            </TabList>
            <TabPanels mt={10}>
                {list.map((item, idx) => (
                    <TabPanel key={idx}>
                        <Flex flexDirection={"column"}>
                            <Flex flexDirection={"column"}>
                                <Text fontSize='16px' textTransform={"capitalize"} fontFamily={'AktivGrotesk-Medium'} color={"#E76751"} fontWeight={700}>{`Descuento ${item.num}`}</Text>
                                {/* <Text fontSize='16px' fontWeight={700} fontFamily={'AktivGrotesk-Medium'} mb={5}>{item.pay}</Text> */}
                                <Divider />
                            </Flex>
                        </Flex>
                        <Flex flexDirection={"column"}>
                            {item.tab.map((element, idx1) => (
                                <Flex key={idx1} flexDirection={"column"} lineHeight={2}>
                                    <Text fontSize='16px' textTransform={"inherit"} fontFamily={'AktivGrotesk-Regular'} fontWeight={700}>{element.item1}</Text>
                                    <Text fontSize='16px' textTransform={"inherit"} fontFamily={'AktivGrotesk-Regular'} fontWeight={700}>{element.item2}</Text>
                                    <Divider />
                                </Flex>
                            ))}
                        </Flex>
                        <br/>
                        <Flex flexDirection={'column'}>
                                <Text textAlign={'center'} fontSize={'9px'} fontFamily={'AktivGrotesk-Regular'}  fontWeight={400} >
                                    La presente política comercial, la cual detalla las diferentes formas de pago, está sujeta a cambios en cualquier momento y sin previo aviso en función de la demanda. Válida solo para el momento de la consulta. Este documento no es, ni sustituye una cotización, el precio total de la operación se determinará en función de los montos variables por conceptos de descuentos, intereses, créditos directos o hipotecarios participantes, así como gastos notariales según apliquen, los cuales pueden ser consultados y cotizados por su asesor inmobiliario de Grupo Guía. Para más información consulta nuestra página web www.grupoguia.mx.  *Precios en M.N (Moneda Nacional).
                                </Text>
                        </Flex>
                    </TabPanel>            
                ))}
            </TabPanels>
        </Tabs>
    )
}

export default TableMobile