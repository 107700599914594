import React from 'react'
import { Flex, Text, useMediaQuery, useTheme,Link,Image  } from '@chakra-ui/react';

const Cercanias = () => {
    const { breakpoints } = useTheme();
    const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.md})`);

    const assets = [
        { imgUrl: isGreaterThanMd ? 'https://boomerang.s3.us-east-2.amazonaws.com/landings/thedistrict/mapa/mapabg.svg' : 'https://boomerang.s3.us-east-2.amazonaws.com/landings/thedistrict/mapa/mapabg-mb.svg' },
        { imgUrl: isGreaterThanMd ? 'https://boomerang.s3.us-east-2.amazonaws.com/landings/thedistrict/mapa/simbologia.svg' : 'https://boomerang.s3.us-east-2.amazonaws.com/landings/thedistrict/mapa/simbologia-mb.svg'},
        { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/icons/maps.svg' }
    ];

  return (
    <Flex paddingTop="2rem" marginBottom={"4rem"} justifyContent='center' py={isGreaterThanMd?4:10}>
            <Flex alignItems={"center"} flexDirection={"column"} color={"#545454"}>
                <Flex alignItems={"center"} textAlign={"center"} flexDirection={"column"} px={4}>
                    <Text 
                        color={'#0F312C'}
                        textTransform={"uppercase"} 
                        fontSize={isGreaterThanMd ? '50px': '25px'}
                        lineHeight={isGreaterThanMd ? '60px' : '36px'}
                        mb={0} 
                        fontFamily={'AktivGrotesk-Medium'}
                        textAlign={'center'}
                    >
                        Vive en el corazón de la zona financiera<br />de Guadalajara
                    </Text>
                    <Text fontFamily={'AktivGrotesk-Regular'} marginTop={"1.5rem"} textAlign={'center'} marginBottom={"1rem"} fontSize={isGreaterThanMd?'24px':'22px'} lineHeight={isGreaterThanMd ? '30.47px': '23.85px'}>
                        Disfruta de una ubicación privilegiada que te conecta con lo mejor de la ciudad: Restaurantes, bares,<br/>centros de negocio, escuelas, entretenimiento, parques, y mucho más.
                    </Text>
                </Flex>
                <Flex justifyContent={"center"}>
                    <img src={assets[0].imgUrl} style={{ width: "100%", height: 'auto' }} width="500" height="500" alt='cercanias' onClick={()=>{window.open('https://goo.gl/maps/rTXPq9ZkAuAHJa3i8', '_blank')}}/>
                </Flex>
                <Flex justifyContent={"center"}>
                    <img src={assets[1].imgUrl} style={{ width: "90%", height: 'auto' }} width="500" height="500" alt='cercanias' onClick={()=>{window.open('https://goo.gl/maps/rTXPq9ZkAuAHJa3i8', '_blank')}}/>
                </Flex>
                <Flex flexDirection={isGreaterThanMd ? "row" : "column"} justifyContent={"center"} alignItems={"center"} mt={4}>
                <Image m={isGreaterThanMd ? "0px 1rem" : "1rem"} src={assets[2].imgUrl} width={isGreaterThanMd ? "52px" : "40px"} height={isGreaterThanMd ? "47px" : "36px"} alt="descripción" />
                <Link  color={"#0882C6"} fontSize={"16px"} href='https://goo.gl/maps/rTXPq9ZkAuAHJa3i8'>
                    {isGreaterThanMd ? 
                    <span>Av. Américas 1619, Providencia, C. P. 44630, Guadalajara, Jalisco.</span> : <span>Av. Américas 1619, Providencia,<br />C. P. 44630, Guadalajara, Jalisco.</span>}
                </Link>
                </Flex>
                
            </Flex>
            
        </Flex>
  )
}

export default Cercanias