import React, {useState} from 'react'
import { Flex, Text, useMediaQuery, useTheme, IconButton } from '@chakra-ui/react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { listImages } from '../resource/list';
import  ArrowLeftIcon  from '../assets/svgs/ArrowLeftSVG';
import  ArrowRightIcon  from '../assets/svgs/ArrowRightSVG';

const Prototipos = () => {
  const { breakpoints } = useTheme();
  const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.md})`);
  const [activeIndex, setActiveIndex] = useState(0);

  const slidePrev = () => {
      activeIndex===0?(
        setActiveIndex(listImages[1].images.length-1)):
      setActiveIndex(activeIndex - 1);
              };

  const slideNext = () => {
      activeIndex===listImages[1].images.length-1?(
        setActiveIndex(0))
          : setActiveIndex(activeIndex + 1)

    };
  return (
    <Flex marginTop={isGreaterThanMd?"8rem":"5rem"} flexDirection={"column"} alignItems={"center"} id='prototipos'>
            <Flex alignItems={"center"} textAlign={"center"} flexDirection={"column"} marginBottom={"1rem"}>
                <Text 
                    color={"#0F312C"}
                    textTransform={"uppercase"} 
                    fontWeight={"700"} 
                    fontSize={isGreaterThanMd ? '50px':'25px'}
                    fontFamily={'AktivGrotesk-Medium'}
                    lineHeight={isGreaterThanMd?'60px':'36px'}
                >
                    Prototipos
                </Text>
                {isGreaterThanMd ?
                    <Text 
                        color={"#545454"}
                        fontWeight={"700"} 
                        fontSize={'26px'}
                        fontFamily={'AktivGrotesk-Regular'}
                    >
                        Conoce nuestros modelos de departamentos de 2 y 3 recámaras
                    </Text> :
                    <Text 
                        color={"#545454"}
                        fontWeight={"700"} 
                        fontSize={'20px'}
                        fontFamily={'AktivGrotesk-Regular'}
                    >
                        Conoce nuestros modelos de<br />departamentos de 2 y 3 recámaras
                    </Text>
                }
            </Flex>
            <Flex width={isGreaterThanMd?"90%":'100%'} direction="row" alignItems="center" placeContent={'center'} >
                <IconButton
                    icon={<ArrowLeftIcon  color="white" />}
                    rounded="full"
                    border="0"
                    shadow="md"
                    transitionDuration=".3s"
                    _hover={{ shadow: "lg" }}
                    //disabled={page <= 1}
                    onClick={slidePrev}
                    position="relative"
                    right={{ base: "-6", md: 10 }}
                    bg="brand.500"
                    zIndex="2"
                    background={'#0F312C'}
                />
                <Flex flexDirection={"column"} width={"90%"} marginBottom={isGreaterThanMd?"4rem":'2rem'}>
                    <AliceCarousel
                        mouseTracking
                        //disableDotsControls
                        //animationType="fadeout" 
                        animationDuration={800}
                        disableButtonsControls
                        activeIndex={activeIndex}
                        items={isGreaterThanMd?listImages[1].images:listImages[1].imagesMobile}
                        responsive={true}
                        controlsStrategy="responsive"
                        animationType='fadeout'
                        autoPlay={true}
                        autoPlayInterval={5000}
                        infinite={true}
                        keyboardNavigation={true}
                        disableDotsControls
                        autoHeight={false}
                        maxHeight="200px"
                    />
                </Flex>
                <IconButton
                    icon={<ArrowRightIcon color="white" />}
                    rounded="full"
                    border="0"
                    colorScheme="brand"
                    shadow="md"
                    transitionDuration=".3s"
                    _hover={{ shadow: "lg" }}
                    onClick={slideNext}
                    position="relative"
                    left={{ base: "-6", md: 10 }}
                    bg="brand.500"
                    zIndex="2"
                    background={'#0F312C'}
                />
            </Flex>
        </Flex>
  )
}

export default Prototipos