import React, { useState, useEffect } from 'react';
import {
    Flex,
    Text,
    useMediaQuery,
    useTheme,
    useDisclosure,
    Stack,
    Box,
    Image,
    Show,
    Hide,
} from '@chakra-ui/react';

import "../styles/styled.css";
import "../styles/home.css";
import Nav from './Nav';
import ModalCard from './ModalCard';

const Presentacion = () => {
    //Elementos para responsive
    const { breakpoints } = useTheme();
    const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.md})`);
    const [isGreaterThanLg] = useMediaQuery(`(min-width: ${breakpoints.lg})`);
    const [id, setId] = useState(1);

    const assets = [
        { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/icons/circle.png' },
        { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/icons/circle-white.png' },
        { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/thedistrict/icons/icon-key.png' },
        // { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/thedistrict/logos/logo-key.svg' },
    ];

    const [img, setImg] = useState('');
    const [text1, setText1] = useState('Invierte desde el 5 % de enganche');
    // const [text2, setText2] = useState('');
    const [text2, setText2] = useState("Departamentos en preventa desde $9.7 MDP*");
    const [text3, setText3] = useState('');
    const [txt1Bold, setTxt1Bold] = useState('AktivGrotesk-Bold');
    const [txt2Bold, setTxt2Bold] = useState('');
    const [txtDirection, setTxtDirection] = useState('start');
    const [nums, setNums] = useState(0);
    const [change, setChange] = useState(true);
    const { isOpen, onClose } = useDisclosure();

    const changeImage = (num) => {
        switch (num) {
            case 1:
                if (isGreaterThanMd) {
                    setImg('https://boomerang.s3.us-east-2.amazonaws.com/landings/thedistrict/hero/slide-1.webp');
                } else { setImg('https://boomerang.s3.us-east-2.amazonaws.com/landings/thedistrict/hero/mobile/MOBILE-1.webp'); }
                setText1("Invierte desde el 5% de enganche");
                setText2("Departamentos en preventa desde $9.7 MDP");
                setText3("");
                setTxt1Bold('AktivGrotesk-Bold');
                setTxt2Bold('AktivGrotesk-Regular');
                setTxtDirection('start');
                setId(1);
                break;
            case 2:
                if (isGreaterThanMd) {
                    setImg('https://boomerang.s3.us-east-2.amazonaws.com/landings/thedistrict/hero/slide-2.webp');
                } else { setImg('https://boomerang.s3.us-east-2.amazonaws.com/landings/thedistrict/hero/mobile/MOBILE-2.webp'); }
                setText1("Un icono arquitectónico");
                setText2("Departamentos en preventa desde $9.7 MDP*");
                setText3("Con las mejores vistas de Guadalajara");
                setTxt1Bold('AktivGrotesk-Bold');
                setTxt2Bold('AktivGrotesk-Regular');
                setTxtDirection('end');
                setId(2);
                break;
            case 3:
                if (isGreaterThanMd) {
                    setImg('https://boomerang.s3.us-east-2.amazonaws.com/landings/thedistrict/hero/slide-3.webp');
                } else { setImg('https://boomerang.s3.us-east-2.amazonaws.com/landings/thedistrict/hero/mobile/MOBILE-3.webp'); }
                setText1("Vive en el corazón de la");
                setText2("Departamentos en preventa desde $9.7 MDP*");
                setText3("zona financiera de guadalajara");
                setTxt1Bold('AktivGrotesk-Regular');
                setTxt2Bold('AktivGrotesk-Bold');
                setTxtDirection('start');
                setId(3);
                break;
            case 4:
                if (isGreaterThanMd) {
                    setImg('https://boomerang.s3.us-east-2.amazonaws.com/landings/thedistrict/hero/slide-4.webp');
                } else { setImg('https://boomerang.s3.us-east-2.amazonaws.com/landings/thedistrict/hero/mobile/MOBILE-4.webp'); }
                setText1("Un icono de exclusividad");
                setText2("Departamentos en preventa desde $9.7 MDP*");
                setText3("Descubre un proyecto de usos mixtos de primer nivel");
                setTxt1Bold('AktivGrotesk-Regular');
                setTxt2Bold('AktivGrotesk-Bold');
                setTxtDirection('start');
                setId(4);
                break;
            default:
                break;
        }
    }

    const changeImg = () => {
        setTimeout(() => {
            var sum = nums + 1;
            setNums(sum);
            setChange(!change);
            if (nums === 4) {
                setNums(1);
            }
            changeImage(nums);
        }, 8000);
    }

    useEffect(() => {
        // Reacciona dependiendo al cambio de pantalla y asigna la imagén ajustada
        if (isGreaterThanMd) {
            setImg(`https://boomerang.s3.us-east-2.amazonaws.com/landings/thedistrict/hero/slide-${id}.webp`);
        } else {
            setImg(`https://boomerang.s3.us-east-2.amazonaws.com/landings/thedistrict/hero/mobile/MOBILE-${id}.webp`);
        }
    }, [isGreaterThanMd]);

    useEffect(() => {
        changeImg();
    }, [change]);

    const showText = () => {
        switch (id) {
            case 1:
                return (
                    <>
                        <Hide below='md'>
                            <Stack ml={5} pt={{ lg: '15vh', xl: '25vh', '2xl': '30vh' }} w={{ lg: '50vh', '2xl': '60vh' }}>
                                <Text fontSize={{ lg: '3xl', '2xl': '5xl' }} fontWeight='600' align='center'>{text1}</Text>
                                <Box p={2} bg='rgba(231, 103, 81, 1)' w='100%'>
                                    <Text
                                        fontSize={{ md: 'lg', lg: 'xl' }}
                                        align='center'
                                        textTransform='uppercase'
                                    >
                                        {text2}
                                    </Text>
                                </Box>
                                <Box position='absolute' top='72vh' left='18vh'>
                                    <Text fontSize='11px'>
                                        *Precios sujetos a cambios sin previo aviso
                                    </Text>
                                </Box>
                                <Stack position='absolute' right='5vh' bottom='26vh'>
                                    <Text fontSize='11px'>
                                        *Imagen ilustrativa sujeta a modificaciones.
                                    </Text>
                                </Stack>
                            </Stack>
                            <Stack position='absolute' bottom='7vh' left='65vh'>
                                <Image src={assets[2].imgUrl} h='auto' />
                            </Stack>
                        </Hide>
                        <Show below='md'>
                            <Stack mt={'12vh'} mx={4} align='center'>
                                <Text fontSize='3xl' fontWeight='600' align='center'>
                                    {text1}
                                </Text>
                                <Box mt='58vh'>
                                    <Text fontSize='11px'>
                                        *Precios sujetos a cambios sin previo aviso
                                    </Text>
                                </Box>
                                <Stack>
                                    <Text fontSize='11px'>
                                        *Imagen ilustrativa sujeta a modificaciones.
                                    </Text>
                                </Stack>
                                <Box p={2} bg='rgba(15, 49, 44, 1)' w='100%'>
                                    <Text
                                        fontSize='18px'
                                        align='center'
                                        textTransform='uppercase'
                                    >
                                        {text2}
                                    </Text>
                                </Box>
                            </Stack>
                        </Show>
                    </>
                )
            case 2:
                return (
                    <>
                        <Hide below='md'>
                            <Stack mt='25vh' align='flex-end' mr={4}>
                                <Box w='45vh'>
                                    <Text fontSize={{ lg: '2xl', '2xl': '3xl' }} fontWeight='600' align='center'>{text1}</Text>
                                    <Text fontSize={{ lg: '2xl', '2xl': '3xl' }} align='center'>{text3}</Text>
                                    <Box p={2} mt={4} bg='rgba(231, 103, 81, 1)' w='100%'>
                                        <Text
                                            fontSize='lg'
                                            align='center'
                                            textTransform='uppercase'
                                        >
                                            {text2}
                                        </Text>
                                    </Box>
                                </Box>
                                <Box position='absolute' top='95vh' right='14vh'>
                                    <Text fontSize='11px'>
                                        *Precios sujetos a cambios sin previo aviso
                                    </Text>
                                </Box>
                            </Stack>
                            <Stack position='absolute' bottom='7vh' left='35vh'>
                                <Image src={assets[2].imgUrl} h='auto' w='40vh' />
                            </Stack>
                        </Hide>
                        <Show below='md'>
                            <Stack mt={'9vh'} mx={4} align='center'>
                                <Text fontSize='3xl' fontWeight='600' align='center'>{text1}</Text>
                                <Text fontSize='3xl' align='center'>{text3}</Text>
                                <Box mt='58vh'>
                                    <Text fontSize='11px'>
                                        *Precios sujetos a cambios sin previo aviso
                                    </Text>
                                </Box>
                                <Stack>
                                    <Text fontSize='11px'>
                                        *Imagen ilustrativa sujeta a modificaciones.
                                    </Text>
                                </Stack>
                                <Box p={2} bg='rgba(15, 49, 44, 1)' w='100%'>
                                    <Text
                                        fontSize='18px'
                                        align='center'
                                        textTransform='uppercase'
                                    >
                                        {text2}
                                    </Text>
                                </Box>
                            </Stack>
                        </Show>
                    </>
                )
            case 3:
                return (
                    <>
                        <Hide below='md'>
                            <Stack ml={5} mt='20vh' w={{ lg: '50vh', '2xl': '60vh' }}>
                                <Text mb={-2} fontSize='2xl' align='center'>
                                    {text1}
                                    <strong> {text3}</strong>
                                </Text>
                                <Box mt={5} p={2} bg='rgba(231, 103, 81, 1)' w='100%'>
                                    <Text
                                        fontSize='lg'
                                        align='center'
                                        textTransform='uppercase'
                                    >
                                        {text2}
                                    </Text>
                                </Box>
                                <Box position='absolute' top='72vh' left='18vh'>
                                    <Text fontSize='11px'>
                                        *Precios sujetos a cambios sin previo aviso
                                    </Text>
                                </Box>
                                <Stack position='absolute' right='5vh' bottom='26vh'>
                                    <Text fontSize='11px'>
                                        *Imagen ilustrativa sujeta a modificaciones.
                                    </Text>
                                </Stack>
                            </Stack>
                            <Stack position='absolute' bottom='7vh' left='65vh'>
                                <Image src={assets[2].imgUrl} h='auto' />
                            </Stack>
                        </Hide>
                        <Show below='md'>
                            <Stack mt={'9vh'} mx={4} align='center'>
                                <Text mb={-2} fontSize='3xl' align='center'>{text1}</Text>
                                <Text fontSize='3xl' fontWeight='600' align='center'>{text3}</Text>
                                <Box mt='58vh'>
                                    <Text fontSize='11px'>
                                        *Precios sujetos a cambios sin previo aviso
                                    </Text>
                                </Box>
                                <Stack>
                                    <Text fontSize='11px'>
                                        *Imagen ilustrativa sujeta a modificaciones.
                                    </Text>
                                </Stack>
                                <Box p={2} bg='rgba(15, 49, 44, 1)' w='100%'>
                                    <Text
                                        fontSize='18px'
                                        align='center'
                                        textTransform='uppercase'
                                    >
                                        {text2}
                                    </Text>
                                </Box>
                            </Stack>
                        </Show>
                    </>
                )
            case 4:
                return (
                    <>
                        <Hide below='md'>
                            <Stack ml={'10vh'} mt='20vh' w={{ lg: '90vh', '2xl': '95vh' }}>
                                <Text mb={-2} fontSize='3xl' align='center' fontWeight='600'>
                                    {text1}
                                </Text>
                                <Text fontSize='3xl' align='center'>
                                    {text3}
                                </Text>
                                <Box mt={5} p={2} bg='rgba(231, 103, 81, 1)' w='100%'>
                                    <Text
                                        fontSize='lg'
                                        align='center'
                                        textTransform='uppercase'
                                    >
                                        {text2}
                                    </Text>
                                </Box>
                                <Box position='absolute' bottom='25vh' left='40vh'>
                                    <Text fontSize='11px' align='center'>
                                        *Precios sujetos a cambios sin previo aviso
                                    </Text>
                                </Box>
                                <Stack position='absolute' bottom='7vh' left='40vh'>
                                    <Image src={assets[2].imgUrl} h='auto' />
                                </Stack>
                                <Stack position='absolute' right='15vh' bottom='5vh'>
                                    <Text fontSize='11px'>
                                        *Imagen ilustrativa sujeta a modificaciones.
                                    </Text>
                                </Stack>
                            </Stack>
                        </Hide>
                        <Show below='md'>
                            <Stack mt={'9vh'} mx={4} align='center'>
                                <Text mb={-2} fontSize='2xl' fontWeight='600' align='center'>{text1}</Text>
                                <Text fontSize='2xl' align='center'>{text3}</Text>
                                <Box mt='58vh'>
                                    <Text fontSize='11px'>
                                        *Precios sujetos a cambios sin previo aviso
                                    </Text>
                                </Box>
                                <Stack>
                                    <Text fontSize='11px'>
                                        *Imagen ilustrativa sujeta a modificaciones.
                                    </Text>
                                </Stack>
                                <Box p={2} bg='rgba(15, 49, 44, 1)' w='100%'>
                                    <Text
                                        fontSize='18px'
                                        align='center'
                                        textTransform='uppercase'
                                    >
                                        {text2}
                                    </Text>
                                </Box>
                            </Stack>
                        </Show>
                    </>
                )
            default:
                break;
        }
    }

    return (
        <>
            <Flex display={"block"}>
                <Nav />
            </Flex>
            <Box bg='#FFF' h={{ base: '40px', md: '85px' }} w='100%'></Box>
            <Flex id='presentacion' padding={{ base: '0rem', md: "1rem" }} height={"100vh"}>
                <Flex
                    mt={{ base: -10, xs: 0 }}
                    flexDirection={isGreaterThanMd ? "row" : "column"}
                    color={"#FFF"}
                    backgroundImage={img}
                    backgroundSize={{ base: 'fit', sm: 'cover' }}
                    backgroundRepeat={'no-repeat'}
                    backgroundPosition={'center'}
                    position={"relative"}
                    width={"100%"}
                    height={"100vh"}
                    borderRadius={"28px"}
                >
                    <Box width={"100%"}>
                        {showText()}
                    </Box>
                    <Flex
                        id='sc-points'
                        justifyContent="end"
                        display={isGreaterThanMd ? 'flex' : 'none'}
                        position={'relative'}
                        alignItems="center"
                        paddingLeft={"2rem"}
                        bottom="8"
                    >
                        <Box position={'absolute'} zIndex={1}>
                            <Flex mb={2} justifyContent={"end"} marginRight={"1rem"} _hover={{ cursor: "pointer" }} className='points' onClick={() => changeImage(1)}>
                                <img src={id === 1 ? assets[0].imgUrl : assets[1].imgUrl} style={{ width: "14px" }} width='50' height='50' alt='icon' />
                            </Flex>
                            <Flex mb={2} justifyContent={"end"} marginRight={"1rem"} _hover={{ cursor: "pointer" }} className='points' onClick={() => changeImage(2)}>
                                <img src={id === 2 ? assets[0].imgUrl : assets[1].imgUrl} style={{ width: "14px" }} width='50' height='50' alt='icon' />
                            </Flex>
                            <Flex mb={2} justifyContent={"end"} marginRight={"1rem"} _hover={{ cursor: "pointer" }} className='points' onClick={() => changeImage(3)}>
                                <img src={id === 3 ? assets[0].imgUrl : assets[1].imgUrl} style={{ width: "14px" }} width='50' height='50' alt='icon' />
                            </Flex>
                            <Flex justifyContent={"end"} marginRight={"1rem"} _hover={{ cursor: "pointer" }} className='points' onClick={() => changeImage(4)}>
                                <img src={id === 4 ? assets[0].imgUrl : assets[1].imgUrl} style={{ width: "14px" }} width='50' height='50' alt='icon' />
                            </Flex>
                        </Box>
                    </Flex>
                </Flex>
            </Flex>
            {isOpen ?
                <ModalCard
                    isOpen={isOpen}
                    onClose={onClose}
                    title={"Quiero ser contactado"}
                    idForm={"22b54777-673f-44b6-bfd8-043fee1a6931"}
                /> : null
            }
        </>
    );
}

export default Presentacion;