import axios from 'axios';
import fileDownload from 'js-file-download';

export const handleDownload = (url, filename) => {
    axios.get(url, {
      responseType: 'blob',
    })
    .then((res) => {
      fileDownload(res.data, filename)
    })
}

export const list = [
    {
        bold: true,
        num: "9%",
        pay: "4 PAGOS",
        tab: [
            { item1: "· 1er pago a la firma del contrato", item2: "30%"},
            { item1: "· 2do pago a los 90 días", item2: "30%"},
            { item1: "· 3er pago a los 180 días", item2: "30%"},
            { item1: "· Último pago contra escritura", item2: "10%"}
        ]
    },
    {
        bold: false,
        num: "6%",
        pay: "5 PAGOS",
        tab: [
            { item1: "· 1er pago a la firma del contrato", item2: "20%"},
            { item1: "· 2do pago a los 120 días de la firma", item2: "20%"},
            { item1: "· 3er pago a los 210 días de la firma", item2: "20%"},
            { item1: "· 4to pago a los 330 días de la firma", item2: "20%"},
            { item1: "· Último pago contra escritura", item2: "20%"}
        ]
    },
    {
        bold: false,
        num: "4%",
        pay: "22 PAGOS",
        tab: [
            { item1: "· 1er pago a la firma del contrato", item2: "15%"},
            { item1: "· 2do pago a los 60 días de la firma", item2: "5%"},
            { item1: "· 3er pago a los 120 días de la firma", item2: "5%"},
            { item1: "· 4to pago financiado a 18 MSI", item2: "25%"},
            { item1: "· Último pago contra escritura", item2: "50%"}
        ]
    },
    {
        bold: false,
        num: "2%",
        pay: "26 PAGOS",
        tab: [
            { item1: "· 1er pago a la firma del contrato", item2: "10%"},
            { item1: "· 2do pago financiado a 24 MSI", item2: "30%"},
            { item1: "· Último pago contra escritura", item2: "60%"}
        ]
    },
    {
        bold: false,
        num: "0%",
        pay: "33 PAGOS",
        tab: [
            { item1: "· 1er pago a la firma del contrato", item2: "5%"},
            { item1: "· 2do pago a los 30 días", item2: "5%"},
            { item1: "· 3er pago financiado a 30 MSI", item2: "20%"},
            { item1: "· Último pago contra escritura", item2: "70%"}
        ]
    }
  ];
