import React from 'react'
import Presentacion from '../../components/Presentacion';
import Description from '../../components/Description';
import Amenidades from '../../components/Amenidades';
import AmenidadesMb from '../../components/Amenidades/AmenidadesMb';
import Carousel from '../../components/Carousel';
import CTA from '../../components/CTA';
import Prototipos from '../../components/Prototipos';
import Planes from '../../components/Planes';
import Cercanias from '../../components/Cercanias';
import Desarrollador from '../../components/Desarrollador';
import Contacto from '../../components/Contacto';
import Footer from '../../components/Footer';
import ContactMobile from '../../components/ContactMobile';
import Button from '../../components/ButtonPage';
import ButtonModal from '../../components/ButtonModal';
import { Flex, Divider, useTheme, useMediaQuery } from '@chakra-ui/react';

const Home = () => {
    const { breakpoints } = useTheme();
    const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.md})`);

    return (
        <>
            {/* <Flex position={'fixed'} right={{base: 2, md: "20px"}} bottom={5} zIndex={1}>
                <Button
                    isImage={true}
                    srcImg={'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/icons/wa.svg'}
                    title={'Envíanos un Whatsapp'}/>
            </Flex> */}
            <Flex position={'fixed'} right={{ base: 2, md: '20px' }} bottom={5} zIndex={3}>
                <ButtonModal
                    title={"Envíanos un Whatsapp"}
                    isImage={true}
                    srcImg={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/icons/redes-sociales/wa.svg'}
                    idFormHs={"d3214e10-6aa2-4b28-ab78-b18b4643b8b2"}
                    thankYouPage={"thankyoupage-contactar-whatsapp"}
                    bgColor={"#D2457A"}
                    hover={"#C42862"}
                    txtSize={700}
                />
            </Flex>
            <Presentacion />
            <Description />
            {
                isGreaterThanMd ?
                    <Amenidades /> : <AmenidadesMb />
            }
            <CTA
                ctaNum={0}
                text1={'Aprovecha nuestros descuentos'}
                text2={'especiales por formas de pago'}
                font1={'50px'}
                font2={'50px'}
                fontMb1={'25px'}
                fontMb2={'25px'} />
            <Carousel />
            <CTA
                ctaNum={1}
                text1={'Exclusividad, practicidad y confort'}
                text2={'Departamentos de 2 y 3 recámaras en el corazón de la zona financiera'}
                font1={'50px'}
                font2={'26px'}
                fontMb1={'25px'}
                fontMb2={'20px'} />
            <Planes />
            <Prototipos />
            <Cercanias />
            <CTA
                ctaNum={2}
                text1={'Sé parte del nuevo ícono arquitectónico'}
                text2={'de Guadalajara'}
                font1={'50px'}
                font2={'50px'}
                fontMb1={'25px'}
                fontMb2={'25px'} />
            <Desarrollador />
            {isGreaterThanMd ? <Contacto /> : <ContactMobile />}
            <Flex paddingLeft={"1.5rem"} paddingRight={"1.5rem"}>
                <Divider orientation='horizontal' />
            </Flex>
            <Footer />
        </>
    )
}

export default Home