import React from 'react';
import { Button, useDisclosure, Image, useMediaQuery } from '@chakra-ui/react';
import { handleDownload } from '../resource';
import brochure from '../resource/pdf/TheDistrict_Brochure_Digital.pdf';
//import ModalCard from './ModalCard';
import ModalContacto from './ModalContacto';

const ButtonModal = ({ title, bg, color, isImage, srcImg, bgHover, colorHover, border, thankYouPage, idForm }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            {isImage ?
                <Image
                    src={srcImg}
                    w={{ base: '50px', md: "50px" }}
                    cursor={'pointer'}
                    //onClick={onOpen}
                    // onClick={() => {
                    //     !isGreaderThanMd ? window.open('https://api.whatsapp.com/send/?phone=3310395872', '_blank') : window.open('https://web.whatsapp.com/send?phone=3310395872', '_blank')
                    // }}
                    onClick={() => onOpen()}
                    _hover={{ transform: 'scale(1.1)' }}
                />
                :
                <Button
                    fontSize={'sm'}
                    variant={'link'}
                    bg={bg}
                    color={color}
                    fontFamily={'AktivGrotesk-Medium'}
                    width={"fit-content"}
                    border={border ? border : "transparent"}
                    padding={2}
                    paddingLeft={4}
                    paddingRight={4}
                    as='a'
                    borderRadius='0px'
                    _hover={{
                        bg: bgHover,
                        color: colorHover,
                        cursor: 'pointer'
                    }}
                    onClick={() => handleDownload(brochure, "TheDistrict_Brochure_Digital.pdf")}>
                    {title}
                </Button>
            }
            {isOpen ?
                <ModalContacto
                    isOpen={isOpen}
                    onClose={onClose}
                    title={title}
                    thankyoupage={thankYouPage}
                    idForm={idForm} /> : null
            }
        </>
    );
}

export default ButtonModal;